import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import _ from "lodash"

export default function CreatePermissionCmp(props) {

    let sinPattern = ''
    const initialState = {
        codigoPermission: {name: 'codigoPermission', type: 'input', value : '', label: 'Código Permission (no debe tener espacios se recomienda _ y deben ser en mayúsculas)', pattern: '', isInvalid: false, required: false},
    }
 
    const [component, setComponent] = useState(initialState);


    const handleChange = (event, callback) => {
        if(event === undefined){
          return;
        }
    
        let targetName=event.target.name;
        let targetValue=event.target.value;
        var newComponent = component;
        newComponent[targetName].value = targetValue;
        newComponent[targetName].error = undefined;
        setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
      }

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    useEffect(() => {
        
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }
        
    }, [props])

    /*
    const inputsAreInvalid = () =>{
        var newComponent = component;
        let inputsAreValid = false;

        if(component.fechaDesde.value === ''){
            component.fechaDesde.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaDesde.name]: newComponent[component.fechaDesde.name] }));
        }
        if(component.fechaHasta.value === ''){
            component.fechaHasta.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaHasta.name]: newComponent[component.fechaHasta.name] }));
        }
        
        return inputsAreValid    
    }
    */


    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function getList(){
        // props.history.push(URLMAPPING.LIST_PERMISSIONS);

        let payload = {
                codigoPermission: component.codigoPermission.value
        }
        console.log("payload", payload)
        props.createPermission(payload)
    }

    return (
        <div>
            <div className={'section-title'}>Create Permission</div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>
                        <Form.Field
                            control={Input}
                            value={component.codigoPermission.value}
                            name={component.codigoPermission.name}
                            label={component.codigoPermission.label}
                            placeholder={component.codigoPermission.placeholder}
                            onChange={(e)=>handleChange(e)} 
                        />
                        <Button size='small' fluid className={'boton-ver-coberturas'} onClick={()=>getList()} type='button'>Create</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
        </div>
    )
}