import * as CONSTANTS from '../constants/constants'

export function pings () {
    return {
        type: CONSTANTS.PREFIX_PING_LOADING
    }
}

export const actions = {
    pings
};