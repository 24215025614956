/* eslint-disable no-constant-condition */
import { takeEvery, put, call, all} from 'redux-saga/effects'
import { invocatorServices } from '../services'
import {API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* getRolePermissions(payload){
  try {
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/roles/${payload.payload}/permissions`);
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_GET_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_GET_ERROR, message: e.message});
  }
}

export function* addPermissionToRole(payload){
  try {
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/roles/${payload.payload.roleId}/add/permission/${payload.payload.permissionId}`);
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_ADD_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_ADD_ERROR, message: e.message});
  }
}

export function* deletePermissionToRole(payload){
  try {
    const result = yield call(invocatorServices.deleteCall,`${API_URL}/api/roles/${payload.payload.roleId}/delete/permission/${payload.payload.permissionId}`);
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_DELETE_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_ROLES_PERMISSIONS_DELETE_ERROR, message: e.message});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.LIST_ROLES_PERMISSIONS_GET_LOADING, getRolePermissions)
  yield takeEvery(CONSTANTS.LIST_ROLES_PERMISSIONS_ADD_LOADING, addPermissionToRole)
  yield takeEvery(CONSTANTS.LIST_ROLES_PERMISSIONS_DELETE_LOADING, deletePermissionToRole)
  yield all([])
}

