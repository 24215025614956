import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Select } from 'semantic-ui-react';
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import { parseRoleSearch } from '../utils/parseRoles'
import _ from "lodash"


export default function AddRoleToUserCmp(props) {


    const initialState = {
        user: {name: 'user', type: 'input', value : '', label: 'User', pattern: '', isInvalid: false, required: false},
    }
 
    const [component, setComponent] = useState(initialState);

    const [users] = useState([]);       

    const [rolesRows, setRolesRows] = useState([]);
    const [roles] = useState([]);       

    const [searched, setSearched] = useState(false);

    const addRoleToUser = (id) => {
        let payload = {userId: component.user.value, roleId: id}
        
        document.getElementById('roles-button-add-' + id).classList.add("disabled");
        document.getElementById('roles-button-delete-' + id).classList.remove("disabled");
        
        props.addRoleToUser(payload)
    }

    const deleteRoleToUser = (id) => {
        let payload = {userId: component.user.value, roleId: id}

        document.getElementById('roles-button-delete-' + id).classList.add("disabled");
        document.getElementById('roles-button-add-' + id).classList.remove("disabled");
        props.deleteRoleToUser(payload)
    }

    
    const setRow = useCallback((id, description) =>{
        
        let retorno =  
                (<tr key={id}>
                   <td data-label='Id'>{id}</td>
                   <td data-label='Descripción'>{description}</td>        
                   <td> 
                       <Button size='small' id={'roles-button-add-'+id} className={'disabled'} onClick={()=>addRoleToUser(id)} type='button'>ADD</Button>
                       <Button size='small' id={'roles-button-delete-'+id} className={'disabled'} onClick={()=>deleteRoleToUser(id)} type='button'>REMOVE</Button>
                   </td> 
               </tr>
               )
        return retorno;
    },[roles])
    
    const setRowError = useCallback((error) =>{
        return  <tr key={1}>
                    <td >{error}</td>
                </tr>
    }, [])

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    const parseRequests = useCallback(()=>{
        let array = [];
        if(props.roles)
        props.roles.forEach(element=>{
          array.push(setRow(
              element.id,
              element.description
          ))
        })
        
        if(array[0] === undefined){
            let error = 'No se encontraron registros'
            
            array.push(setRowError(error))
        }
    
        setRolesRows(array);
    }, [props, setRow, setRowError]);

    const handleChange = (event, callbackPost) => {
        if(event === undefined){
          return;
        }
    
        let targetName=event.target.name;
        let targetValue=event.target.value;
        var newComponent = component;
        newComponent[targetName].value = targetValue;
        newComponent[targetName].error = undefined;
        setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
        if (typeof callbackPost === "function")
        callbackPost(targetValue, targetName);
    }
    
    const fetchUserRoles = (id) => {
        setSearched(true)
        props.getListUserRoles(id);
    }
    
    const disableButtonsAdded = () => {
        if(props.userRoles)

        props.roles.forEach((elem1)=>{

            document.getElementById('roles-button-delete-' + elem1.id).removeAttribute("disabled");
            document.getElementById('roles-button-delete-' + elem1.id).classList.remove("disabled");

            document.getElementById('roles-button-add-' + elem1.id).removeAttribute("disabled");
            document.getElementById('roles-button-add-' + elem1.id).classList.remove("disabled");
            
            
            
            let includes = _.find(props.userRoles, (elem2)=>{
                return elem1.id === elem2.id;
            })
            let disabled = false;
            if(includes){
                disabled = true;
            }

            if(disabled){
                document.getElementById('roles-button-add-' + elem1.id).classList.add("disabled");
            }
            else{
                document.getElementById('roles-button-delete-' + elem1.id).classList.add("disabled");
                
            }
                
        })
        
    }
    
    useEffect(() => {

        if(props.users === undefined && props.usersStatus !== CONSTANTS.LIST_USERS_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_USERS_GET_ERROR){
            props.getListUsers();
        }

        if(users[0] === undefined && props.users !== undefined) { 
            parseRoleSearch(users, props.users, {id: 'id', value: 'id', text: 'username'});
        }
        
        /*if(props.roles !== undefined && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_ERROR){
            parseRequests();
        }*/
        
        if(props.roles === undefined && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_ERROR){
            props.getListRoles();
        }

        if(props.roles !== undefined && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_ERROR){
            parseRequests();
        }
        
        
        if(searched && props.userRoles !== undefined 
            && props.userRolesStatus !== CONSTANTS.LIST_USERS_ROLES_ADD_LOADING 
            && props.userRolesStatus !== CONSTANTS.LIST_USERS_ROLES_ADD_ERROR
            && props.userRolesStatus !== CONSTANTS.LIST_USERS_ROLES_GET_LOADING
            && props.userRolesStatus !== CONSTANTS.LIST_USERS_ROLES_GET_ERROR){
            disableButtonsAdded();
            setSearched(false)
        }

    },[props, parseRequests])

    return (
        <div>
            <div className={'section-title'}>Elegir Role</div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Select}
                            options={users}
                            value={component.user.value}
                            placeholder={component.user.label}
                            name={component.user.name}
                            label={component.user.label}
                            onChange={(e, o)=>handleChange({target:{name :component.user.name, value: o.value}}, fetchUserRoles)}
                            error={component.user.error}
                        />
                    </Form.Group>
                </Form>
            </React.Fragment>
            <div className='container'>
                <div className={'section-title'}>Listado Permisos</div>
                {<table className='ui celled table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Descripcion</th>
                            <th>Agregado</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-list">
                        {rolesRows}
                    </tbody>
                    </table>}
            </div>
        </div>
    )
}