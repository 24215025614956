import React from "react";

const HeaderCmp = (props) => {

    return (
        <div>
            <div className="header">
                
            </div>
        </div>
      );
    }
    
export default HeaderCmp;