import * as CONSTANTS from '../constants/constants'

export function getListRoles () {
    return {
        type: CONSTANTS.LIST_ROLES_GET_LOADING
    }
}

export const actions = {
    getListRoles
};