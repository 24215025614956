export const parseEntitySearch = (entityState, entitiesProps) => {
    entityState.push({
      id: '', 
      value: '', 
      text: 'Limpiar Entidad'
    })
    if(entitiesProps)
      parseOptions(entityState, entitiesProps);
    return entityState;
};

export const parseOptions = (stateOptions, propsOptions) => {
  if(propsOptions)
    propsOptions.forEach(element => stateOptions.push({
      id: element.id, 
      value: element.id, 
      text: element.entity.toUpperCase()
    }));
  return stateOptions;
};

export const getNameEntity = (entityId, entitiesProps) => {
  var entityName;
  if(entitiesProps.length > 0)
    entityName = entitiesProps.filter(element => parseInt(element.id) === parseInt(entityId))[0].text;
  return entityName;
};