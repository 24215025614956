/* eslint-disable no-constant-condition */
import { takeEvery, put, call, all} from 'redux-saga/effects'
import { invocatorServices } from '../services'
import {API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* getUserRoles(payload){
  try {
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/users/${payload.payload}/roles`);
    yield put({type: CONSTANTS.LIST_USERS_ROLES_GET_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_USERS_ROLES_GET_ERROR, message: e.message});
  }
}

export function* addRoleToUser(payload){
  try {
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/users/${payload.payload.userId}/add/roles/${payload.payload.roleId}`);
    yield put({type: CONSTANTS.LIST_USERS_ROLES_ADD_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_USERS_ROLES_ADD_ERROR, message: e.message});
  }
}

export function* deleteRoleToUser(payload){
  try {
    const result = yield call(invocatorServices.deleteCall,`${API_URL}/api/users/${payload.payload.userId}/delete/roles/${payload.payload.roleId}`);
    yield put({type: CONSTANTS.LIST_USERS_ROLES_DELETE_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_USERS_ROLES_DELETE_ERROR, message: e.message});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.LIST_USERS_ROLES_GET_LOADING, getUserRoles)
  yield takeEvery(CONSTANTS.LIST_USERS_ROLES_ADD_LOADING, addRoleToUser)
  yield takeEvery(CONSTANTS.LIST_USERS_ROLES_DELETE_LOADING, deleteRoleToUser)
  yield all([])
}
