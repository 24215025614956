import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Input, Select, Icon, Pagination } from 'semantic-ui-react';
import DatePickerCmp from './UI/DatePickerCmp'
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import { parseEntitySearch } from '../utils/parseEntities'
// import Footer from "./FooterCmp";
import _ from "lodash"
import Cookies from 'js-cookie';

export default function ListRequestsCmp(props) {

    const tipos = [
        { key: "EMISION", text: "EMISIÓN", value: "EMISION" },
        { key: "COTIZACION", text: "COTIZACIÓN", value: "COTIZACION" },
      ];
    const [showAccordion] = useState([]);

    let sinPattern = ''
    const initialState = {
        fechaDesde: {name: 'fechaDesde', type: 'datepicker', value : new Date(), label:'Desde', pattern: sinPattern, isInvalid: false, required: true},
        fechaHasta: {name: 'fechaHasta', type: 'datepicker', value : new Date(), label:'Hasta', pattern: sinPattern, isInvalid: false, required: true},
        entidad: {name: 'entidad', type: 'select', value : '', label:'Entidad', pattern: '', isInvalid: false, required: false},
        tipo: {name: 'tipo', type: 'select', value : '', label:'Tipo', pattern: '', isInvalid: false, required: false},
        ocurrenciaRequest: {name: 'ocurrenciaRequest', type: 'input', value : '', label:'Ocurrencia Request', pattern: '', isInvalid: false, required: false},
        ocurrenciaResponse: {name: 'ocurrenciaResponse', type: 'input', value : '', label:'Ocurrencia Response', pattern: '', isInvalid: false, required: false},
    }
 
    const [component, setComponent] = useState(initialState);
    const [incommingRequestsRows, setIncommingRequestsRows] = useState([]);
    const [searchingIncommingRequests, setSearchingIncommingRequests] = useState(false);
    const [entidades] = useState([]);       
    const [disablePagination, setDisablePagination] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const formatDate = useCallback((dateString) =>{
        //debugger;
        let date;
        date = new Date(dateString);
        let day = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();
        return zeroPad(day,2) + '/' + zeroPad(month,2) + '/' + year;
    },[])

    const cropField = (field) => {
        if(field){
            return field.substring(0,30)
        }
            
    }
    

    const evaluateAccordion = useCallback((id) => {
        return showAccordion[id]
    },[showAccordion])

    const largerAttributes = array => {
        let arrayAccordion = []
        _.forEach(array , (item) =>{
            arrayAccordion.push(<div key={item.id} className="accordion-cell">
                                    <div className={"accordion-value"}>
                                        <div className="accordion-label">
                                            {item.label}:
                                        </div>
                                        <br></br>
                                        {item.value}
                                    </div>
                                </div>)
        })
       return arrayAccordion; 
    }

    const setAccordion = useCallback((element) => {
        //console.log("elements:", element.soldProductAttributes)
        if(element.request !== undefined ){
        let arrayElement = [
            {id: 1, label: "request", value: element.request}, 
            {id: 2, label: "headers", value: element.headers}, 
            {id: 3, label: "response", value: element.response}
        ]
        
        return (
            <tr key={"accordion-tr-" + element.id} id={"accordion-" + element.id} className={evaluateAccordion(element.id)}>
                <td colSpan={12} key={"accordion-td-" + element.id}>
                {largerAttributes(arrayElement)}  
                </td>
            </tr>
        )}
    },[evaluateAccordion])

    const handleShowAccordion = useCallback((e, id) => {
        showAccordion[id] = showAccordion[id] === "show-accordion" ? "hide-accordion" : "show-accordion";
        document.getElementById("accordion-"+id).classList = "accordion-container "+ showAccordion[id]
        document.getElementById("accordion-icon-"+id).classList = showAccordion[id] === "hide-accordion" ? "dropdown icon accordion-icon-close" : "dropdown icon accordion-icon-open"
    },[showAccordion])

    const setRow = useCallback((id, entidad, type, request, headers, response, creationDate, updateDate, duration) =>{
        showAccordion[id] = "hide-accordion";
        let cropRequest = cropField(request)
        let cropHeaders = cropField(headers)
        let cropResponse = cropField(response)
        let retorno =  
                (<tr key={id} onClick={(e)=>handleShowAccordion(e, id)}>
                   <td data-label='Id'><Icon id={"accordion-icon-"+ id} className={"accordion-icon-close"} name='dropdown'/>{id}</td>
                   <td data-label='Entidad'>{entidad}</td>
                   <td data-label='Entidad'>{type}</td>
                   <td data-label='Requests'>{cropRequest}...</td>
                   <td data-label='Headers'>{cropHeaders}...</td>
                   <td data-label='Responses'>{cropResponse}...</td> 
                   <td data-label='Fecha de Creación'>{creationDate}</td>
                   <td data-label='Fecha de Actualización'>{updateDate}</td>
                   <td data-label='Duración'>{duration/1000} segs</td>                 
               </tr>
               )

        return retorno;
    },[entidades, handleShowAccordion, showAccordion])
    
    const setRowError = useCallback((error) =>{
        return  <tr key={1}>
                    <td >{error}</td>
                </tr>
    }, [])


    const handleChange = (event, callback) => {
        if(event === undefined){
          return;
        }
    
        let targetName=event.target.name;
        let targetValue=event.target.value;
        var newComponent = component;
        newComponent[targetName].value = targetValue;
        newComponent[targetName].error = undefined;
        setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
      }

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    const parseRequests = useCallback(()=>{
        let array = [];
        setDisablePagination(false)
        //console.log("pages: ", props.incommingRequests.pagesCount)
        setPageCount(props.incommingRequests.pagesCount)
        props.incommingRequests.page.forEach(element=>{
          array.push(setRow(
              element.id,
              element.entity,
              element.type,
              element.request,
              element.headers,
              element.response,
              formatDate(element.creationDate),
              formatDate(element.updateDate),
              element.durationTime
          ))
          array.push(setAccordion(element))
        })
        
        if(array[0] === undefined){
            let error = 'No se encontraron registros'
            
            array.push(setRowError(error))
        }
    
        setIncommingRequestsRows(array);
    }, [formatDate, props, setRow, setRowError]);

    useEffect(() => {
        
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }
        
        if(searchingIncommingRequests && props.incommingRequests !== undefined && CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_LOADING !== props.incommingRequestsStatus && props.incommingRequestsStatus !== CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_ERROR){
          parseRequests();
          setSearchingIncommingRequests(false);
        }

          
        if(props.entidades === undefined && props.entidadesStatus !== CONSTANTS.LIST_PREFIX_ENTIDADES_LOADING && props.entidadesStatus !== CONSTANTS.LIST_PREFIX_ENTIDADES_ERROR){
          props.getEntidades();
        }
          
        if(entidades[0] === undefined && props.entidades !== undefined) { 
          
          parseEntitySearch(entidades, props.entidades);
        }
    }, [setSearchingIncommingRequests, entidades, parseRequests, props, redirectToLogin, searchingIncommingRequests, parseEntitySearch])

    const inputsAreInvalid = () =>{
        var newComponent = component;
        let inputsAreValid = false;

        if(component.fechaDesde.value === ''){
            component.fechaDesde.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaDesde.name]: newComponent[component.fechaDesde.name] }));
        }
        if(component.fechaHasta.value === ''){
            component.fechaHasta.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaHasta.name]: newComponent[component.fechaHasta.name] }));
        }
        
        return inputsAreValid    
    }

    

      const zeroPad = (num, places) => String(num).padStart(places, '0')


    function getList(pageNumberParam){
        
        pageNumberParam = pageNumberParam === undefined ? 0 : (pageNumberParam-1)

        if(inputsAreInvalid()){
            return;
        }

        setIncommingRequestsRows([])
        // console.log(Cookies.getJSON('account').organization)
        let fechaDesde = component.fechaDesde.value;
        let fechaHasta = component.fechaHasta.value;
        let entityId = component.entidad.value;
        let type = component.tipo.value;
        let ocurrenciaRequest = component.ocurrenciaRequest.value;
        let ocurrenciaResponse = component.ocurrenciaResponse.value;
        
        let payload = {
            pathVariable: {username: JSON.parse(Cookies.get('account')).organization},
            payload: {
                from: fechaDesde, 
                to: fechaHasta, 
                entityId: entityId, 
                pageNumber: pageNumberParam,
                type: type,
                ocurrenciaRequest: ocurrenciaRequest, 
                ocurrenciaResponse: ocurrenciaResponse
            }
        }

        props.getRequests(payload)
        setSearchingIncommingRequests(true);
    }

    const changePage = (event, data) =>{
        getList(data.activePage)
    }

    return (
        <div>
            <div className={'section-title'}>Filtros</div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Select}
                            options={entidades}
                            value={component.entidad.value}
                            placeholder={component.entidad.label}
                            name={component.entidad.name}
                            label={component.entidad.label}
                            onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                            error={component.entidad.error}
                        />
                        <Form.Field
                            control={Select}
                            options={tipos}
                            value={component.tipo.value}
                            placeholder={component.tipo.label}
                            name={component.tipo.name}
                            label={component.tipo.label}
                            onChange={(e, o)=>handleChange({target:{name :component.tipo.name, value: o.value}})}
                            error={component.tipo.error}
                        />

                        <DatePickerCmp 
                            error={component.fechaDesde.error}
                            label={component.fechaDesde.label} 
                            selected={component.fechaDesde.value}
                            placeholderText={component.fechaDesde.label}
                            name={component.fechaDesde.name}
                            onChange={handleChange}
                        />  
                        <DatePickerCmp 
                            error={component.fechaHasta.error}
                            label={component.fechaHasta.label} 
                            selected={component.fechaHasta.value}
                            placeholderText={component.fechaHasta.label}
                            name={component.fechaHasta.name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        
                        <Form.Field
                            control={Input}
                            value={component.ocurrenciaRequest.value}
                            name={component.ocurrenciaRequest.name}
                            label={component.ocurrenciaRequest.label}
                            placeholder={component.ocurrenciaRequest.placeholder}
                            fluid icon='search'
                            onChange={(e)=>handleChange(e)} 
                        />
                        <Form.Field
                            control={Input}
                            value={component.ocurrenciaResponse.value}
                            name={component.ocurrenciaResponse.name}
                            label={component.ocurrenciaResponse.label}
                            placeholder={component.ocurrenciaResponse.placeholder}
                            fluid icon='search'
                            onChange={(e)=>handleChange(e)} 
                        />
                        <Button size='small' fluid className={'boton-ver-coberturas'} onClick={()=>getList()} type='button'>Buscar</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
            <div className='container'>
                <div className={'section-title'}>Listado Requests</div>
                {<table className='ui celled table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Entidad</th>
                            <th>Tipo</th>
                            <th>Requests</th>
                            <th>Headers</th>
                            <th>Responses</th>
                            <th>Fecha de Creación</th>
                            <th>Fecha de Actualización</th>
                            <th>Duración</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-list">
                        {incommingRequestsRows}
                    </tbody>
                    </table>}
            </div>
            {/*<Footer active={true}/>*/}
            <Pagination disabled={disablePagination} defaultActivePage={1} totalPages={pageCount} onPageChange={changePage} />
        </div>
    )
}