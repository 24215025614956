/* eslint-disable no-constant-condition */
import { takeEvery, /*take,*/ put, call, /*fork,*/ /*select,*/ all  } from 'redux-saga/effects'
import { invocatorServices } from '../services'
//import Cookies from 'js-cookie';
//import {API_LOGIN_URL, API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* checkToken() {
  try {
    const result = yield call(invocatorServices.checkToken,`https://septest.colonseguros.com.ar/oauth2/oauth/check_token`);
    //console.log(result)
    yield put({type: CONSTANTS.CHECK_TOKEN_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.CHECK_TOKEN_ERROR, message: e.message});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.CHECK_TOKEN_LOADING, checkToken)

  yield all([])
}

