export const parseRoleSearch = (roleState, rolesProps, element) => {
    if(rolesProps)
        parseOptions(roleState, rolesProps, element);
    return roleState;
};

export const parseOptions = (stateOptions, propsOptions, elem) => {
  if(propsOptions)
    propsOptions.forEach((element) => {
        stateOptions.push({
        id: element[elem.id], 
        value: element[elem.value], 
        text: element[elem.text].toUpperCase()
      })
    });
  return stateOptions;
};