import * as CONSTANTS from '../constants/constants'

export function checkToken() {
    return {
        type: CONSTANTS.CHECK_TOKEN_LOADING
    }
}

export const actions = {
    checkToken, 
};