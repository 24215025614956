/* eslint-disable no-constant-condition */
import { takeEvery, put, call, all  } from 'redux-saga/effects'
import { invocatorServices } from '../services'
import {API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* createRole(payload){
  try {
    const result = yield call(invocatorServices.postCall,`${API_URL}/api/roles/create`, payload);
    yield put({type: CONSTANTS.CREATE_ROLE_SAVE_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.CREATE_ROLE_SAVE_ERROR, message: e.message});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.CREATE_ROLE_SAVE_LOADING, createRole)

  yield all([])
}

