
import PingsCmp from '../components/PingsCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { pings } = require('../actions/pingsAction')

const mapStateToProps = (state) => {
    return{
      ping: state.mainReducer.ping,
      pingStatus: state.mainReducer.pingStatus,
      emision: state.mainReducer.respuesta,
      status: state.tokenReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  pings: () => {
    dispatch(pings());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(PingsCmp);
