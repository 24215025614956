import React from "react";
import HeaderCmp from './UI/HeaderCmp'

export default function Layout({ children }) {
  return (
      <div id="layoutId">
        <HeaderCmp/>
        <div className={"body-container"}>
          <React.Fragment>
            {children}
          </React.Fragment>
        </div>
        <div id="footerId"></div>
      </div>
  );
}