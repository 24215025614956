import * as CONSTANTS from '../constants/constants'

export function getListRolePermissions (payload) {
    return {
        type: CONSTANTS.LIST_ROLES_PERMISSIONS_GET_LOADING,
        payload
    }
}

export function addPermisionToRole (payload) {
    return {
        type: CONSTANTS.LIST_ROLES_PERMISSIONS_ADD_LOADING,
        payload
    }
}

export function deletePermisionToRole (payload) {
    return {
        type: CONSTANTS.LIST_ROLES_PERMISSIONS_DELETE_LOADING,
        payload
    }
}

export const actions = {
    getListRolePermissions,
    addPermisionToRole,
    deletePermisionToRole
};