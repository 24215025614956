
import ListRequestsCmp from '../components/ListRequestsCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { getRequests, getEntidades } = require('../actions/listRequestsAction')

const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      entidades: state.mainReducer.entidades,
      entidadesStatus: state.mainReducer.entidadesStatus,
      incommingRequests: state.mainReducer.incommingRequests,
      incommingRequestsStatus: state.mainReducer.incommingRequestsStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getRequests: (payload) => {
    dispatch(getRequests(payload));
  },
  getEntidades: () => {
    dispatch(getEntidades());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ListRequestsCmp);
