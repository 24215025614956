import * as CONSTANTS from '../constants/constants'

export function getListPermissions () {
    return {
        type: CONSTANTS.LIST_PERMISSIONS_GET_LOADING
    }
}

export const actions = {
    getListPermissions
};