//import { schema, normalize } from 'normalizr'
//import { camelizeKeys } from 'humps'
import 'isomorphic-fetch'
import Cookies from 'js-cookie';

function getTokenFromCookie(){
  let token
  try{
    // console.log(Cookies.getJSON('account'))
    token = Cookies.getJSON('account').access_token;
  }
  catch(e){
    //console.log(e)
  }
  return token
}

function getTokenFromLocalStorage(){
  let token
  try{
    token = JSON.parse(localStorage.getItem('account')).access_token;
    //console.log("token: ", token)
  }
  catch(e){
    //console.log(e)
  }
  return token
}

function callGET(endpoint) {
  //console.log("callGET")
  let token = getTokenFromCookie();
  //let token = getTokenFromLocalStorage();
  //console.log("token", token);
  return fetch(endpoint, {

    method: "GET", headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }})
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json;
  })
  .then(
    response => (response)
  )
}

function callDELETE(endpoint) {
  //console.log("callGET")
  let token = getTokenFromCookie();
  //let token = getTokenFromLocalStorage();
  //console.log("token", token);
  return fetch(endpoint, {

    method: "DELETE", headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }})
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json;
  })
  .then(
    response => (response)
  )
}

function callPOST(endpoint, request) {
  console.log("callPOST", endpoint, request.payload)
  let token = getTokenFromCookie();
  // let token = getTokenFromLocalStorage();
  return fetch(endpoint, {
                          method: "POST", headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                          },
                          body: JSON.stringify(request.payload)
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function callPUT(endpoint, request) {
  //let token = getTokenFromCookie();
  let token = getTokenFromLocalStorage();
  return fetch(endpoint, {
                          method: "PUT", headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                          },
                          body: JSON.stringify(request)
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

const tokenBasic = 'YnJvd3NlckNsaWVudElkUGFzc3dvcmQ6c2VjcmV0';

function callLoginPOST(endpoint, request) {
  //console.log("username", request.payload.username)
  //console.log("password", request.payload.password)
  let body = "grant_type=password&username="+ request.payload.username +"&password="+ request.payload.password+"&client_id=browserClientIdPassword"
  //console.log("callLoginPOST", request)
  return fetch(endpoint, {
                          method: "POST",
                          headers: {  "Content-Type": "application/x-www-form-urlencoded",
                                      "Authorization": 'Basic ' + tokenBasic},
                          body: body
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function sendMailChangePasswordPOST(endpoint, request) {
  let body = request
  //console.log("sendMailChangePassword: ",endpoint, request)
  return fetch(endpoint, {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: body
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function checkEmailPOST(endpoint, request) {
  let body = request
  let token = getTokenFromLocalStorage();
  return fetch(endpoint, {
                          method: "POST",
                          headers: { 
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token 
                          },
                          body: body
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function changePasswordPOST(endpoint, request) {
  let body = request
  //console.log("sendMailChangePassword: ",endpoint, request)
  return fetch(endpoint, {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify(body)
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function callLogoutPOST(endpoint) {
  //console.log("callLogoutPOST", endpoint)
  //let token = getTokenFromCookie();
  let token = getTokenFromLocalStorage();
  return fetch(endpoint, {
                          method: "POST", headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                          },
                          body: null
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      console.error("logout error")
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function checkTokenGET(endpoint) {

  //console.log("checkTokenGET")
  //let token = getTokenFromCookie();
  let token = getTokenFromLocalStorage();
  let url  = endpoint + '?token=' + token
  return fetch(url, {
                      method: "POST",
                      headers: {  "Content-Type": "application/x-www-form-urlencoded",
                                  "Authorization": 'Basic ' + tokenBasic}
                  }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function unsecureGET(endpoint) {

  let url  = endpoint 
  return fetch(url, {
                      method: "POST",
                      headers: {  "Content-Type": "application/json"}
                  }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}


function callFileGET(endpoint) {
  //console.log("callFileGET")
  //let token = getTokenFromCookie();
  let token = getTokenFromLocalStorage();
  //console.log("token", token);
  return fetch(endpoint, {

    method: "GET", headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }})
  .then(response =>{
    if (!response.ok) {
      return Promise.reject(response)
    }
    return response.blob();
  })
}


export const getCall = endpoint => callGET(endpoint)

export const deleteCall = endpoint => callDELETE(endpoint)
export const getUnsecureCall = endpoint => unsecureGET(endpoint)

export const putCall = (endpoint, payload) => callPUT(endpoint, payload)
export const getFile = endpoint => callFileGET(endpoint)
export const postCall = (endpoint, payload) => callPOST(endpoint, payload)

export const loginPOST = (endpoint, payload) => callLoginPOST(endpoint, payload)
export const logoutPOST = (endpoint) => callLogoutPOST(endpoint)
export const checkToken = (endpoint) => checkTokenGET(endpoint)
export const sendMailChangePassword = (endpoint, payload) => sendMailChangePasswordPOST(endpoint, payload)
export const checkEmail = (endpoint, payload) => checkEmailPOST(endpoint, payload)
export const changePassword = (endpoint, payload) => changePasswordPOST(endpoint, payload)
