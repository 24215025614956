/* eslint-disable no-constant-condition */
import { takeEvery, /*take,*/ put, call, /*fork,*/ /*select,*/ all  } from 'redux-saga/effects'
import { invocatorServices } from '../services'
//import Cookies from 'js-cookie';
import { API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* getListPermissions() {
  try {
    //console.log("get entidades")
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/permissions`);
    yield put({type: CONSTANTS.LIST_PERMISSIONS_GET_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_PERMISSIONS_GET_ERROR, message: e.message});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.LIST_PERMISSIONS_GET_LOADING, getListPermissions);

  yield all([])
}

