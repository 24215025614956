import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import _ from "lodash"

export default function ListRolesCmp(props) {


    const [rolesRows, setRolesRows] = useState([]);
    const [roles] = useState([]);       


    const setRow = useCallback((id, description) =>{
        let retorno =  
                (<tr key={id}>
                   <td data-label='Id'>{id}</td>
                   <td data-label='Descripción'>{description}</td>         
               </tr>
               )

        return retorno;
    },[roles])
    
    const setRowError = useCallback((error) =>{
        return  <tr key={1}>
                    <td >{error}</td>
                </tr>
    }, [])

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    const parseRequests = useCallback(()=>{
        let array = [];
        console.log("props.roles: ", props.roles)
        if(props.roles)
        props.roles.forEach(element=>{
          array.push(setRow(
              element.id,
              element.description
          ))
        })
        
        if(array[0] === undefined){
            let error = 'No se encontraron registros'
            
            array.push(setRowError(error))
        }
    
        setRolesRows(array);
    }, [props, setRow, setRowError]);

    useEffect(() => {
        
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }
          
        if(props.roles === undefined && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_ERROR){
          props.getListRoles();
        }

        if(props.roles !== undefined && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_LOADING && props.rolesStatus !== CONSTANTS.LIST_ROLES_GET_ERROR){
            parseRequests();
          }
          
    }, [roles, props, redirectToLogin, parseRequests])

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function addRole(){
        
        props.history.push(URLMAPPING.CREATE_ROLE);
    }

    return (
        <div>
            <div className={'section-title'}></div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>
                        <Button size='small' fluid className={'boton-ver-coberturas'} onClick={()=>addRole()} type='button'>Create Role</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
            <div className='container'>
                <div className={'section-title'}>Listado roles</div>
                {<table className='ui celled table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-list">
                        {rolesRows}
                    </tbody>
                    </table>}
            </div>
            
        </div>
    )
}