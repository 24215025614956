import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './routes/Root'
import configureStore from './store/configureStore'
import 'semantic-ui-css/semantic.min.css'
import './styles/styles.scss';
import * as URLMAPPING from './config/UrlMapping';

/************************************/
/*          IMOPRT SAGAS            */
/************************************/
import rootSaga from './sagas/rootSaga'
import pingsSaga from './sagas/pingsSaga'
import listRolesSaga from './sagas/listRolesSaga'
import listPermissionsSaga from './sagas/listPermissionsSaga'
import listRequestsSaga from './sagas/listRequestsSaga'
import createRoleSaga from './sagas/createRoleSaga'
import createPermissionSaga from './sagas/createPermissionSaga'
import addPermissionToRoleSaga from './sagas/addPermisionToRoleSaga'
import addRoleToUserSaga from './sagas/addRoleToUserSaga'
import listUsersSaga from './sagas/listUsersSaga'

const store = configureStore()

/************************************/
/*          ADD SAGAS HERE          */
/************************************/

store.runSaga(rootSaga)
store.runSaga(pingsSaga)
store.runSaga(listRequestsSaga)
store.runSaga(createRoleSaga)
store.runSaga(createPermissionSaga)
store.runSaga(listRolesSaga)
store.runSaga(listPermissionsSaga)
store.runSaga(addPermissionToRoleSaga)
store.runSaga(addRoleToUserSaga)
store.runSaga(listUsersSaga)


/************************************/

render(
  <Router basename={URLMAPPING.HOME_ROOT}>
    <Root store={store}  basename={URLMAPPING.HOME_ROOT} />
  </Router>,
  document.getElementById('root')
)
