
import ListRolesCmp from '../components/ListRolesCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { getListRoles } = require('../actions/listRoles')

const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      roles: state.mainReducer.roles,
      rolesStatus: state.mainReducer.rolesStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getListRoles: () => {
    dispatch(getListRoles());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ListRolesCmp);
