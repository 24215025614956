import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import PingsCnt from '../containers/PingsCnt';
import ListRequestsCnt from '../containers/ListRequestsCnt';
import CreateRoleCnt from '../containers/CreateRoleCnt';
import CreatePermissionCnt from '../containers/CreatePermissionCnt';
import AddRoleToUserCnt from '../containers/AddRoleToUserCnt';
import AddPermissionToRoleCnt from '../containers/AddPermissionToRoleCnt';
import ListPermissionsCnt from '../containers/ListPermissionsCnt';
import ListRolesCnt from '../containers/ListRolesCnt';
import * as URLMAPPING from '../config/UrlMapping';

import Layout from "../components/Layout";
const Root = ({ store }) => (
  <Provider store={store}>
    <div>
    <BrowserRouter>
      <Switch>
        <Layout>
          {/*<Route path={URLMAPPING.HOME_ROOT} component={HomeCnt} />*/}
          <Route path={URLMAPPING.PINGS} component={PingsCnt} />
          <Route path={URLMAPPING.LIST_REQUESTS} component={ListRequestsCnt} />
          <Route path={URLMAPPING.CREATE_ROLE} component={CreateRoleCnt} />
          <Route path={URLMAPPING.CREATE_PERMISSION} component={CreatePermissionCnt} />
          <Route path={URLMAPPING.ADD_ROLE_TO_USER} component={AddRoleToUserCnt} />
          <Route path={URLMAPPING.ADD_PERMISSION_TO_ROLE} component={AddPermissionToRoleCnt} />
          <Route path={URLMAPPING.LIST_PERMISSIONS} component={ListPermissionsCnt} />
          <Route path={URLMAPPING.LIST_ROLES} component={ListRolesCnt} />
        </Layout>
      </Switch>
    </BrowserRouter>
    </div>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
