
import AddPermissionToRoleCmp from '../components/AddPermissionToRoleCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { getListPermissions } = require('../actions/listPermissions')
const { getListRoles } = require('../actions/listRoles')
const { getListRolePermissions, addPermisionToRole, deletePermisionToRole } = require('../actions/addPermisionToRole')

const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      permissions: state.mainReducer.permissions,
      permissionsStatus: state.mainReducer.permissionsStatus,
      roles: state.mainReducer.roles,
      rolesStatus: state.mainReducer.rolesStatus,
      rolePermissions: state.mainReducer.rolePermissions,
      rolePermissionsStatus: state.mainReducer.rolePermissionsStatus,
      permissionAdded: state.mainReducer.permissionAdded,
      permissionAddedStatus: state.mainReducer.permissionAddedStatus,
      permissionDeleted: state.mainReducer.permissionDeleted,
      permissionDeletedStatus: state.mainReducer.permissionDeletedStatus      
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getListPermissions: () => {
    dispatch(getListPermissions());
  },
  getListRoles: () => {
    dispatch(getListRoles());
  },
  getListRolePermissions: (payload) => {
    dispatch(getListRolePermissions(payload));
  },
  addPermisionToRole: (payload) => {
    dispatch(addPermisionToRole(payload));
  },
  deletePermisionToRole: (payload) => {
    dispatch(deletePermisionToRole(payload))
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(AddPermissionToRoleCmp);
