import merge from "lodash/merge";
import { combineReducers } from "redux";
import * as CONSTANTS from "../constants/constants";

const loginState = {
  status: CONSTANTS.NOT_LOGGED,
};

const tokenReducer = (state = { loginState }, action) => {
  if (
    action.type !== CONSTANTS.CHECK_TOKEN_SUCCESS &&
    action.type !== CONSTANTS.CHECK_TOKEN_ERROR
  )
    return state;

  if (action.type === CONSTANTS.CHECK_TOKEN_SUCCESS) {
    return merge({}, state, { status: CONSTANTS.LOGGED });
  }

  if (action.type === CONSTANTS.CHECK_TOKEN_ERROR) {
    return merge({}, state, { status: CONSTANTS.NOT_LOGGED });
  }

  if (action.userData && action.userData.response) {
    let token = action.userData.response.access_token;
    if (token !== undefined)
      return merge(
        {},
        state,
        { logged: action.userData.response },
        { status: CONSTANTS.LOGGED }
      );
  }
  return merge({}, state, { status: CONSTANTS.NOT_LOGGED });
};

let mapping = {};

const generateMapping = (reducerName, mapa) => {
  
  Object.keys(mapa).forEach((item) => {
    mapping[[CONSTANTS[reducerName + "_" + item + "_LOADING"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
    mapping[[CONSTANTS[reducerName + "_" + item + "_SUCCESS"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
    mapping[[CONSTANTS[reducerName + "_" + item + "_ERROR"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
  });
};

let listPrefixes = []
let mappingParameters = {};

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_PREFIX = "LIST_PREFIX";

listPrefixes.push(LIST_PREFIX)

mappingParameters[LIST_PREFIX] = {
  ENTIDADES: "entidades",
  INCOMMING_REQUESTS: "incommingRequests",
};

generateMapping(LIST_PREFIX, mappingParameters[LIST_PREFIX]);

/**************************************/

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const PREFIX = "PREFIX";

listPrefixes.push(PREFIX)

mappingParameters[PREFIX] = {
  PING: "ping"
};

generateMapping(PREFIX, mappingParameters[PREFIX]);

/**************************************/

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_ROLES = "LIST_ROLES";

listPrefixes.push(LIST_ROLES)

mappingParameters[LIST_ROLES] = {
  GET: "roles"
};

generateMapping(LIST_ROLES, mappingParameters[LIST_ROLES]);

/**************************************/

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_PERMISSIONS = "LIST_PERMISSIONS";

listPrefixes.push(LIST_PERMISSIONS)

mappingParameters[LIST_PERMISSIONS] = {
  GET: "permissions"
};

generateMapping(LIST_PERMISSIONS, mappingParameters[LIST_PERMISSIONS]);

/**************************************/

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const CREATE_ROLE = "CREATE_ROLE";

listPrefixes.push(CREATE_ROLE)

mappingParameters[CREATE_ROLE] = {
  SAVE: "createRole"
};

generateMapping(CREATE_ROLE, mappingParameters[CREATE_ROLE]);

/**************************************/

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const CREATE_PERMISSION = "CREATE_PERMISSION";

listPrefixes.push(CREATE_PERMISSION)

mappingParameters[CREATE_PERMISSION] = {
  SAVE: "createPermission"
};

generateMapping(CREATE_PERMISSION, mappingParameters[CREATE_PERMISSION]);

/**************************************/


/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_ROLES_PERMISSIONS = "LIST_ROLES_PERMISSIONS";

listPrefixes.push(LIST_ROLES_PERMISSIONS)

mappingParameters[LIST_ROLES_PERMISSIONS] = {
  GET: "rolePermissions",
  ADD: "permissionAdded",
  DELETE: "permissionDeleted"
};

generateMapping(LIST_ROLES_PERMISSIONS, mappingParameters[LIST_ROLES_PERMISSIONS]);

/**************************************/


/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_USERS_ROLES = "LIST_USERS_ROLES";

listPrefixes.push(LIST_USERS_ROLES)

mappingParameters[LIST_USERS_ROLES] = {
  GET: "userRoles",
  ADD: "roleAdded",
  DELETE: "roleDeleted"
};

generateMapping(LIST_USERS_ROLES, mappingParameters[LIST_USERS_ROLES]);

/**************************************/


/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

const LIST_USERS = "LIST_USERS";

listPrefixes.push(LIST_USERS)

mappingParameters[LIST_USERS] = {
  GET: "users"
};

generateMapping(LIST_USERS, mappingParameters[LIST_USERS]);

/**************************************/

const evaluatePrefix = (action) => {
  
  let filtered =  listPrefixes.filter((it)=>{
    if(action.type.startsWith(it))
      return it;
  }) 

  if(filtered[0])
    return false;

  return true
}

const mainReducer = (state = { loginState }, action) => {
  
  // if (action.type.startsWith(PREFIX) === false) return state;
  if(evaluatePrefix(action))
    return state;
  
  if (action.type.endsWith("LOADING") || action.type.endsWith("ERROR")) {
    let newState = { [mapping[action.type].fetchStatus]: action.type };
    return merge([], state, newState);
  }
  
  let response = action.response;


  if (response && action.type.endsWith("SUCCESS")) {
    let newState = state;
    newState[mapping[action.type].result] = response;
    newState[mapping[action.type].fetchStatus] = action.type;
    return merge({}, state, newState);
  }
  return merge({}, state, action.payload);
};

const rootReducer = combineReducers({
  tokenReducer,
  mainReducer
});

export default rootReducer;
