import * as CONSTANTS from '../constants/constants'

export function createPermission (payload) {
    return {
        type: CONSTANTS.CREATE_PERMISSION_SAVE_LOADING,
        payload
    }
}


export const actions = {
    createPermission
};