
import AddRoleToUserCmp from '../components/AddRoleToUserCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { getListUsers } = require('../actions/listUsers')
const { getListRoles } = require('../actions/listRoles')
const { getListUserRoles, addRoleToUser, deleteRoleToUser } = require('../actions/addRoleToUser')


const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      users: state.mainReducer.users,
      usersStatus: state.mainReducer.usersStatus,
      roles: state.mainReducer.roles,
      rolesStatus: state.mainReducer.rolesStatus,
      userRoles: state.mainReducer.userRoles,
      userRolesStatus: state.mainReducer.userRolesStatus,
      roleAdded: state.mainReducer.roleAdded,
      roleAddedStatus: state.mainReducer.roleAddedStatus,
      roleDeleted: state.mainReducer.roleDeleted,
      roleDeletedStatus: state.mainReducer.roleDeletedStatus      
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getListUsers: () => {
    dispatch(getListUsers());
  },
  getListRoles: () => {
    dispatch(getListRoles());
  },
  getListUserRoles: (payload) => {
    dispatch(getListUserRoles(payload));
  },
  addRoleToUser: (payload) => {
    dispatch(addRoleToUser(payload));
  },
  deleteRoleToUser: (payload) => {
    dispatch(deleteRoleToUser(payload))
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleToUserCmp);
