
import CreateRoleCmp from '../components/CreateRoleCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { createRole } = require('../actions/createRoleAction')

const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      createRole: state.mainReducer.createRole,
      createRoleStatus: state.mainReducer.createRoleStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  createRole: (payload) => {
    dispatch(createRole(payload));
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleCmp);
