import * as CONSTANTS from '../constants/constants'

export function getRequests (payload) {
    return {
        type: CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_LOADING,
        payload
    }
}

export function getEntidades() {
    return {
        type: CONSTANTS.LIST_PREFIX_ENTIDADES_LOADING
    }
}

export const actions = {
    getEntidades,
    getRequests
};