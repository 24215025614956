import React, { useState, useEffect, useCallback} from "react";
import _ from 'lodash'
import * as CONSTANTS from "../constants/constants";
// import * as URLMAPPING from '../config/UrlMapping';

import { Button } from "semantic-ui-react";

 const PingsCmp = (props) => {
//   props.checkToken();

//   const redirectToLogin = useCallback(() =>{
//     props.history.push(URLMAPPING.LOGIN)
//   }, [props.history])
  

//   const [showContainer, setShowContainer] = useState();

   //useEffect(() => {
    // let updateContainer = true
    // if (props.status === CONSTANTS.NOT_LOGGED) {
    //   redirectToLogin();
    // }
    // checkApp()
    // document.addEventListener("mousemove", (e) => {
    //   if(updateContainer === true){
    //     if( e.offsetY <= 65){
    //       setShowContainer("body-container-hide")
    //     }
    //     else{
    //       setShowContainer("body-container-show")
    //     }
    //   }
    // });

  //   return function cleanup() {
  //     updateContainer = false
  //   }
  //  }, [props])
 
  const [list, setList] = useState();
   
  const checkApp = useCallback(() => {
    
    props.pings()
    setSearchingPings(true)

  })

  const setRow = (element) =>{
    
    return <tr key={element.id}>
               <td data-label="Nombre">{element.name}</td>
               <td className={"div-status-"+element.appStatus} data-label="Estado">{element.appsStatus}</td>
           </tr>
  }

  const render = useCallback((response) => {
    
      setSearchingPings(false)
      if(response){
        
        setList(_.map((response), (element)=>{
          return setRow(element)
        }))
    
      }
  })

  const [searchingPings, setSearchingPings] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      checkApp();
    }, 60000);

    
    if(searchingPings && props.ping !== undefined && CONSTANTS.PREFIX_PING_LOADING !== props.pingStatus && props.pingStatus !== CONSTANTS.PREFIX_PING_ERROR){
      render(props.ping)
    }
    
    return () => clearInterval(interval); 
  }, [checkApp])

return (
    <div>
        <div className="container">
            <div className={'section-title'}>Listado Pings</div>
            <Button size='small' onClick={()=>checkApp()} type='button'>Refrescar</Button>
            <table className="ui celled table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {list}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default PingsCmp;
