import * as CONSTANTS from '../constants/constants'

export function getListUserRoles (payload) {
    return {
        type: CONSTANTS.LIST_USERS_ROLES_GET_LOADING,
        payload
    }
}

export function addRoleToUser (payload) {
    return {
        type: CONSTANTS.LIST_USERS_ROLES_ADD_LOADING,
        payload
    }
}

export function deleteRoleToUser (payload) {
    return {
        type: CONSTANTS.LIST_USERS_ROLES_DELETE_LOADING,
        payload
    }
}

export const actions = {
    getListUserRoles,
    addRoleToUser,
    deleteRoleToUser
};