/* eslint-disable no-constant-condition */
import { takeEvery, /*take,*/ put, call, /*fork,*/ /*select,*/ all  } from 'redux-saga/effects'
import { invocatorServices } from '../services'
//import Cookies from 'js-cookie';
import {API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'

export function* getEntidades() {
  try {
    //console.log("get entidades")
    const result = yield call(invocatorServices.getCall,`${API_URL}/api/entities`);
    yield put({type: CONSTANTS.LIST_PREFIX_ENTIDADES_SUCCESS, response: result});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_PREFIX_ENTIDADES_ERROR, message: e.message});
  }
}

export function* getRequests({payload}) {
  try {
    // console.log("getRequests payload: ", payload)
    const result = yield call(invocatorServices.postCall,`${API_URL}/api/incomming-requests`, payload);
    yield put({type: CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_SUCCESS, response: result.response});
  } catch (e) {
    yield put({type: CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_ERROR});
  }
}

export default function* root() {
  
  yield takeEvery(CONSTANTS.LIST_PREFIX_ENTIDADES_LOADING, getEntidades);
  yield takeEvery(CONSTANTS.LIST_PREFIX_INCOMMING_REQUESTS_LOADING, getRequests);

  yield all([])
}

