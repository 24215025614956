
export const LOGIN = 'https://septest.colonseguros.com.ar'

export const HOME_ROOT = '/'
export const PINGS = '/pings'
export const LIST_REQUESTS = '/list-requests'
export const LIST_PERMISSIONS = '/list-permissions'
export const LIST_ROLES = '/list-roles'
export const CREATE_ROLE = '/create-role'
export const CREATE_PERMISSION = '/create-permission'
export const ADD_ROLE_TO_USER = '/add-role-to-user'
export const ADD_PERMISSION_TO_ROLE = '/add-permission-to-role'
