import * as CONSTANTS from '../constants/constants'

export function getListUsers () {
    return {
        type: CONSTANTS.LIST_USERS_GET_LOADING
    }
}

export const actions = {
    getListUsers
};