
import ListPermissionsCmp from '../components/ListPermissionsCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { getListPermissions } = require('../actions/listPermissions')

const mapStateToProps = (state) => {
    return{
      status: state.tokenReducer.status,
      permissions: state.mainReducer.permissions,
      permissionsStatus: state.mainReducer.permissionsStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getListPermissions: () => {
    dispatch(getListPermissions());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ListPermissionsCmp);
