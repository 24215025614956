/***************LOGIN **************/
/***********************************/

export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT_LOADING = 'LOGOUT_LOADING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const CHECK_TOKEN_LOADING = 'CHECK_TOKEN_LOADING'
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR'

/***********************************/

export const PREFIX_PING_LOADING = 'PREFIX_PING_LOADING'
export const PREFIX_PING_SUCCESS = 'PREFIX_PING_SUCCESS'
export const PREFIX_PING_ERROR = 'PREFIX_PING_ERROR'

export const NOT_LOGGED = 'NOT_LOGGED'
export const LOGGED = 'LOGGED'

export const INVALID = 'INVALID'
export const VALID = 'VALID'

/***********************************/

export const LIST_PREFIX_INCOMMING_REQUESTS_LOADING = 'LIST_PREFIX_INCOMMING_REQUESTS_LOADING'
export const LIST_PREFIX_INCOMMING_REQUESTS_SUCCESS = 'LIST_PREFIX_INCOMMING_REQUESTS_SUCCESS'
export const LIST_PREFIX_INCOMMING_REQUESTS_ERROR = 'LIST_PREFIX_INCOMMING_REQUESTS_ERROR'

export const LIST_PREFIX_ENTIDADES_LOADING = 'LIST_PREFIX_ENTIDADES_LOADING'
export const LIST_PREFIX_ENTIDADES_SUCCESS = 'LIST_PREFIX_ENTIDADES_SUCCESS'
export const LIST_PREFIX_ENTIDADES_ERROR = 'LIST_PREFIX_ENTIDADES_ERROR'

/**********************************/

export const RESET_NOTIFICATION_SERVICE_LOADING = 'RESET_NOTIFICATION_SERVICE_LOADING'

/**********************************/

export const LIST_ROLES_GET_LOADING = 'LIST_ROLES_GET_LOADING'
export const LIST_ROLES_GET_SUCCESS = 'LIST_ROLES_GET_SUCCESS'
export const LIST_ROLES_GET_ERROR = 'LIST_ROLES_GET_ERROR'

/**********************************/

export const LIST_PERMISSIONS_GET_LOADING = 'LIST_PERMISSIONS_GET_LOADING'
export const LIST_PERMISSIONS_GET_SUCCESS = 'LIST_PERMISSIONS_GET_SUCCESS'
export const LIST_PERMISSIONS_GET_ERROR = 'LIST_PERMISSIONS_GET_ERROR'

/**********************************/

export const CREATE_ROLE_SAVE_LOADING = 'CREATE_ROLE_SAVE_LOADING'
export const CREATE_ROLE_SAVE_SUCCESS = 'CREATE_ROLE_SAVE_SUCCESS'
export const CREATE_ROLE_SAVE_ERROR = 'CREATE_ROLE_SAVE_ERROR'

/**********************************/

export const CREATE_PERMISSION_SAVE_LOADING = 'CREATE_PERMISSION_SAVE_LOADING'
export const CREATE_PERMISSION_SAVE_SUCCESS = 'CREATE_PERMISSION_SAVE_SUCCESS'
export const CREATE_PERMISSION_SAVE_ERROR = 'CREATE_PERMISSION_SAVE_ERROR'

/**********************************/

export const LIST_ROLES_PERMISSIONS_GET_LOADING = 'LIST_ROLES_PERMISSIONS_GET_LOADING'
export const LIST_ROLES_PERMISSIONS_GET_SUCCESS = 'LIST_ROLES_PERMISSIONS_GET_SUCCESS'
export const LIST_ROLES_PERMISSIONS_GET_ERROR = 'LIST_ROLES_PERMISSIONS_GET_ERROR'

export const LIST_ROLES_PERMISSIONS_ADD_LOADING = 'LIST_ROLES_PERMISSIONS_ADD_LOADING'
export const LIST_ROLES_PERMISSIONS_ADD_SUCCESS = 'LIST_ROLES_PERMISSIONS_ADD_SUCCESS'
export const LIST_ROLES_PERMISSIONS_ADD_ERROR = 'LIST_ROLES_PERMISSIONS_ADD_ERROR'

export const LIST_ROLES_PERMISSIONS_DELETE_LOADING = 'LIST_ROLES_PERMISSIONS_DELETE_LOADING'
export const LIST_ROLES_PERMISSIONS_DELETE_SUCCESS = 'LIST_ROLES_PERMISSIONS_DELETE_SUCCESS'
export const LIST_ROLES_PERMISSIONS_DELETE_ERROR = 'LIST_ROLES_PERMISSIONS_DELETE_ERROR'

/**********************************/

export const LIST_USERS_ROLES_GET_LOADING = 'LIST_USERS_ROLES_GET_LOADING'
export const LIST_USERS_ROLES_GET_SUCCESS = 'LIST_USERS_ROLES_GET_SUCCESS'
export const LIST_USERS_ROLES_GET_ERROR = 'LIST_USERS_ROLES_GET_ERROR'

export const LIST_USERS_ROLES_ADD_LOADING = 'LIST_USERS_ROLES_ADD_LOADING'
export const LIST_USERS_ROLES_ADD_SUCCESS = 'LIST_USERS_ROLES_ADD_SUCCESS'
export const LIST_USERS_ROLES_ADD_ERROR = 'LIST_USERS_ROLES_ADD_ERROR'

export const LIST_USERS_ROLES_DELETE_LOADING = 'LIST_USERS_ROLES_DELETE_LOADING'
export const LIST_USERS_ROLES_DELETE_SUCCESS = 'LIST_USERS_ROLES_DELETE_SUCCESS'
export const LIST_USERS_ROLES_DELETE_ERROR = 'LIST_USERS_ROLES_DELETE_ERROR'

/**********************************/

export const LIST_USERS_GET_LOADING = 'LIST_USERS_GET_LOADING'
export const LIST_USERS_GET_SUCCESS = 'LIST_USERS_GET_SUCCESS'
export const LIST_USERS_GET_ERROR = 'LIST_USERS_GET_ERROR'

/**********************************/