import * as CONSTANTS from '../constants/constants'

export function createRole (payload) {
    return {
        type: CONSTANTS.CREATE_ROLE_SAVE_LOADING,
        payload
    }
}


export const actions = {
    createRole
};