
import CreatePermissionCmp from '../components/CreatePermissionCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/tokenAction')
const { createPermission } = require('../actions/createPermissionAction')

const mapStateToProps = (state) => {
  return{
    status: state.tokenReducer.status,
    createPermission: state.mainReducer.createPermission,
    createPermissionStatus: state.mainReducer.createPermissionStatus
  }
};

const mapDispatchToProps = dispatch => ({
checkToken: () => {
  dispatch(checkToken());
},
createPermission: (payload) => {
  dispatch(createPermission(payload));
}
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePermissionCmp);
