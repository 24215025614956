import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import _ from "lodash"

export default function ListPermissionssCmp(props) {


    const [permissionsRows, setPermissionssRows] = useState([]);
    const [permissions] = useState([]);       


    const setRow = useCallback((id, description) =>{
        let retorno =  
                (<tr key={id}>
                   <td data-label='Id'>{id}</td>
                   <td data-label='Descripción'>{description}</td>         
               </tr>
               )

        return retorno;
    },[permissions])
    
    const setRowError = useCallback((error) =>{
        return  <tr key={1}>
                    <td >{error}</td>
                </tr>
    }, [])

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    const parseRequests = useCallback(()=>{
        let array = [];
        console.log("props.permissions: ", props.permissions)
        if(props.permissions)
        props.permissions.forEach(element=>{
          array.push(setRow(
              element.id,
              element.description
          ))
        })
        
        if(array[0] === undefined){
            let error = 'No se encontraron registros'
            
            array.push(setRowError(error))
        }
    
        setPermissionssRows(array);
    }, [props, setRow, setRowError]);

    useEffect(() => {
        
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }
          
        if(props.permissions === undefined && props.permissionsStatus !== CONSTANTS.LIST_PERMISSIONS_GET_LOADING && props.permissionsStatus !== CONSTANTS.LIST_PERMISSIONS_GET_ERROR){
          props.getListPermissions();
        }

        if(props.permissions !== undefined && props.permissionsStatus !== CONSTANTS.LIST_PERMISSIONS_GET_LOADING && props.permissionsStatus !== CONSTANTS.LIST_PERMISSIONS_GET_ERROR){
            parseRequests();
          }
          
    }, [permissions, props, redirectToLogin, parseRequests])

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function addPermissions(){
        
        props.history.push(URLMAPPING.CREATE_PERMISSION);
    }

    return (
        <div>
            <div className={'section-title'}></div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>
                        <Button size='small' fluid className={'boton-ver-coberturas'} onClick={()=>addPermissions()} type='button'>Create Permissions</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
            <div className='container'>
                <div className={'section-title'}>Listado Permisos</div>
                {<table className='ui celled table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-list">
                        {permissionsRows}
                    </tbody>
                    </table>}
            </div>
            
        </div>
    )
}